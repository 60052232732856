import React from 'react';
import './muestra.css';

function Muestra(){
  return(

    <div className='muestra'>

        <section className='seccionMuestra'>

          <section className='areaMuestra'>

            <div className='lineaMuestra'>
              {/* Lineas */}
            </div>
            <div className='lineaMuestra'>
              {/* Lineas */}
            </div>
            <div className='fondoMuestra'>

              <h1 className='textoMuestra'>
                VISITA NUESTRO CATÁLOGO
              </h1>

              <a href="/catalogo">
                <button className='botonMuestra'>
                  AQUÍ
                </button>
              </a>

            </div>

          </section>

        </section>

        <section className='seccionMuestra'>

          <section className='areaMuestra'>

            <div className='lineaMuestra'>
              {/* Lineas */}
            </div>
            <div className='lineaMuestra'>
              {/* Lineas */}
            </div>
            <div className='fondoMuestra'>

              <h1 className='textoMuestra'>
                CONOCE NUESTRO SHOWROOM
              </h1>
              
              <a href="/catalogo">
                <button className='botonMuestra'>
                  AQUÍ
                </button>              
              </a>

            </div>

          </section>

        </section>

    </div>

  )
}

export default Muestra;