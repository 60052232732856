import React, {useState, useRef} from 'react';
import './contacto.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha'

import convenioMarco from '../../imagenes/convenioMarco.jpg';

function Contacto() {

  const[nombreContacto, setNombreContacto] = useState([{}]);
  const[emailContacto, setEmailContacto] = useState([{}]);
  const[telefonoContacto, setTelefonoContacto] = useState([{}]);
  const[empresaContacto, setEmpresaContacto] = useState([{}]);
  const[mensajeContacto, setMensajeContacto] = useState([{}]);

  const[recaptchaToken, setRecaptchaToken] = useState(null);

  const recaptchaCambio = (token) => {
    setRecaptchaToken(token);
  };

  const recaptcha = useRef(null);

  const manejarEnvio = async (evento) => {
    evento.preventDefault();
    if (!recaptchaToken){
      Swal.fire({title: 'Precaución', text: 'Porfavor verifica el reCaptcha', icon: 'warning'});
    } else{
      axios.post("/formulariocontacto", {nombreContacto, emailContacto, telefonoContacto, empresaContacto, mensajeContacto, recaptchaToken}).then(respuesta => {Swal.fire({title:'Exitosamente!', text: 'Su mensaje de contacto ha sido envíado por correo', icon:'success'})}).catch(error => console.error(error));
    }
  };

  return (

    <div className='contacto'>

      <section className='areaContacto'>

        <section className='escritoContacto'>
          <section className='seccionContacto'>
            <h1 className='tituloContacto'>
              ¡Contáctanos!
            </h1>
            <p className='parrafoContacto'>
              Si tienes algo que contarnos sobre nuestro servicio, busca más información sobre nuestros productos o debes agendar una visita, te invitamos a contactarnos.
              <br />
              Escríbenos a hsm@imex.cl o llámanos al 2 2396 4723, nuestro equipo responderá todas tus dudas. 
              <br />
              <br />
              ¡Estamos aquí para ayudarte!
            </p>
            <section className='seccionImagenMetida'>
              <img src={convenioMarco} alt="Informacion de Convenio" className='imagenContactoMetida'/>
            </section>
          </section>
        </section>

        <form method='POST' onSubmit={manejarEnvio} className='formularioContacto'>

          <p className="textosPlanos">
            Nombre
          </p>
          <input type="text" name='nombreContacto' onChange={(evento) => setNombreContacto(evento.target.value)} placeholder='Nombre Apellido' className='ingresoTexto'/>

          <p className="textosPlanos">
            Email
          </p>
          <input type="email" name='emailContacto' onChange={(evento) => setEmailContacto(evento.target.value)} placeholder='Email' className='ingresoTexto'/>

          <p className="textosPlanos">
            Teléfono
          </p>
          <input type="number" name='telefonoContacto' onChange={(evento) => setTelefonoContacto(evento.target.value)} placeholder='+569' className='ingresoTexto'/>

          <p className="textosPlanos">
            Empresa
          </p>
          <input type="text" name='empresaContacto' onChange={(evento) => setEmpresaContacto(evento.target.value)} placeholder='Empresa' className='ingresoTexto'/>

          <p className="textosPlanos">
            Mensaje
          </p>
          <textarea name='mensajeContacto' onChange={(evento) => setMensajeContacto(evento.target.value)} placeholder='Tu mensaje' className="mensaje" />

          <ReCAPTCHA className='reCaptcha' onChange={recaptchaCambio} ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />

          <button type="submit" className='botonEnvio'>
            Envíar
          </button>

        </form>

        <section className='seccionImagen'>
          <img src={convenioMarco} alt="Informacion de Convenio" className='imagenContacto'/>
        </section>

      </section>

    </div>

  )
}

export default Contacto;