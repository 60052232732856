import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import { TiArrowBack } from "react-icons/ti";

import './producto.css';

function DetalleProducto() {

  const {id} = useParams();
  const [datosDetalleProducto, setCatalogoFiltrado] = useState([{}]);
  const [datosInformeTecnicoProducto, setInformeTecnicoProducto] = useState([{}]);

  useEffect(() => {
    fetch(`/catalogo/${id}`).then(
      respuestaProducto => respuestaProducto.json()
    ).then(
      datosProducto => {
        setCatalogoFiltrado(datosProducto);
        fetch(`/catalogo/fichatecnica/${id}`).then(
          respuestaInformeTecnico => respuestaInformeTecnico.json()
        ).then(
          datosInformeTecnico => {
            setInformeTecnicoProducto(datosInformeTecnico);
          }
        )
      }
    )
  }, [id]);

  return (

    <div className='areaProducto'>

      <section className="seccionProductoVertical">

        <section className='seccionProductoHorizontal'>

          <section className='seccionVisualProducto'>
            <a href="/catalogo">
              <TiArrowBack  className='iconoVolverAtras'/>
            </a>
            <img src={datosDetalleProducto.rutaImagen} alt={datosDetalleProducto.descripcionImagen} className='tamannoImagen' />
          </section>

          <section className='seccionProducto'>

            <h1 className='nombreProducto'>
              {datosDetalleProducto.nombreProducto}
            </h1>

            <h1 className='codigoProducto'>
              {datosDetalleProducto.codigoProducto}
            </h1>

            {datosInformeTecnicoProducto['linkFichaTecnica'] !== "#"? 
              <a href={datosInformeTecnicoProducto.linkFichaTecnica}>
                <button className='botonCotizar' >
                  Descarga su Ficha AQUÍ
                </button>
              </a>
            : null}

          </section>

        </section>


        <section className='seccionInformacionProducto'>


          <section className='seccionDescripcion'>
            <h1>
              Información del Producto
            </h1>
            <hr />
            <p className='descripcionProducto'>
              {datosInformeTecnicoProducto['Descripcion']}
            </p>
          </section>

          <section className='seccionFichaTecnica'>
            <h1>
              Ficha Técnica
            </h1>
            <hr />
            <table className='tablaFichaTecnica'>

              <tbody>
                <tr className="cuadrosTablaFichaTecnica">
                  <td className="bordeIntermedioTablaFichaTecnica">
                    Codigo
                  </td>
                  <td>
                    {datosInformeTecnicoProducto['codigoProducto']}
                  </td>
                </tr>
              </tbody>

              {datosInformeTecnicoProducto['Tipo de Corte'] !== "no aplica"? 
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Tipo de Corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Tipo de Corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Anchura de corte'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Anchura de Corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Anchura de corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Capacidad de corte'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Capacidad de corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Capacidad de corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Potencia de corte']!== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Potencia de Corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Potencia de corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Tamano de corte'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Tamaño de corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Tamano de corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Velocidad de corte'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Velocidad de corte
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Velocidad de corte']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Nivel de seguridad (DIN 66399)'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Nivel de seguridad (DIN 66399)
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Nivel de seguridad (DIN 66399)']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Nivel de seguridad (DIN 32757-1)'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Nivel de seguridad (DIN 32757-1)
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Nivel de seguridad (DIN 32757-1)']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['ID Convenio Marco'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      ID Convenio Marco
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['ID Convenio Marco']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Anchura'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Anchura
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Anchura']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Altura'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Altura
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Altura']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Profundidad'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Profundidad
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Profundidad']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Peso'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Peso
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Peso']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Material cortado']!== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Material cortado
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Material cortado']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Consumo de potencia del motor'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Consumo de potencia del motor
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Consumo de potencia del motor']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Frecuencia'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Frecuencia
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Frecuencia']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Tension']!== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Tension
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Tension']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Nivel sonoro (marcha en vacio)'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Nivel sonoro (marcha en vacio)
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Nivel sonoro (marcha en vacio)']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Codigo EAN'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Codigo EAN
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Codigo EAN']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Numero de articulo'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Numero de articulo
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Numero de articulo']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Anchura de entrada'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Anchura de entrada
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Anchura de entrada']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Altura de entrada'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Altura de entrada
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Altura de entrada']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Volumen recipiente'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Volumen recipiente
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Volumen recipiente']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Peso fardo'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Peso fardo
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Peso fardo']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Fuerza de prensado'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Fuerza de prensado
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Fuerza de prensado']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Potencia de transmision'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Potencia de transmision
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Potencia de transmision']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Ancho de trabajo'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                    Ancho de trabajo
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Ancho de trabajo']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Longitud de la bala'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Longitud de la bala
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Longitud de la bala']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Anchura de la bala'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Anchura de la bala
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Anchura de la bala']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Altura de la bala'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Altura de la bala
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Altura de la bala']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Duracion del ciclo en marcha en vacio'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Duracion del ciclo en marcha en vacio
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Duracion del ciclo en marcha en vacio']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Peso de la balas (max.)'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Peso de la balas (max.)
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Peso de la balas (max.)']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Numero de ataduras'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Numero de ataduras
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Numero de ataduras']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Medio de atadura'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Medio de atadura
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Medio de atadura']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Altura de llenado'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Altura de llenado
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Altura de llenado']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Rendimiento por hora/balas'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Rendimiento por hora/balas
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Rendimiento por hora/balas']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Rendimiento de paso botellas/hora'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Rendimiento de paso botellas/hora
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Rendimiento de paso botellas/hora']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Anchura de la apertura de llenado'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Anchura de la apertura de llenado
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Anchura de la apertura de llenado']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Nro de articulo cinta/alambre'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Nro de articulo cinta/alambre
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Nro de articulo cinta/alambre']}
                    </td>
                  </tr>
                </tbody>
              : null}

              {datosInformeTecnicoProducto['Rendimiento'] !== "no aplica"?
                <tbody>
                  <tr className="cuadrosTablaFichaTecnica">
                    <td className="bordeIntermedioTablaFichaTecnica">
                      Rendimiento
                    </td>
                    <td>
                      {datosInformeTecnicoProducto['Rendimiento']}
                    </td>
                  </tr>
                </tbody>
              : null}
            </table>
          </section>

        </section>

      </section>

    </div>

  )
}

export default DetalleProducto;