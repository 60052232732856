import React from 'react';
import './footer.css';

import { Link } from 'react-scroll';

import { FaLocationDot, FaXTwitter, FaClock  } from "react-icons/fa6";
import { BsTelephoneFill } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { IoMdMail, IoMdDownload } from "react-icons/io";

import logo from '../../imagenes/logo.jpg';

function Footer(){

  return (

    <div className='footer'>

      <section className='seccionEmpresa'>

        <a href="/" className='linkLogoFooter'>
          <img src={logo} alt='Logo de HSM' className='logoFooter' />
        </a>

        <div className='areaLinks'>

          <section className='seccionLinks'>
            <a href='/' className='link'>
              HOME
            </a>
          </section>

          <section className='seccionLinks'>
            <button className='botonLink'>
              <Link smooth={true} to="elementoHeader" duration={92}>
                CATEGORÍAS
              </Link>
            </button>
          </section>

          <section className='seccionLinks'>
            <a href='/nosotros' className='link'>
              NOSOTROS
            </a>
          </section>

          <section className='seccionLinks'>
            <a href='/catalogo' className='link'>
              CATÁLOGO
            </a>
          </section>

          <section className='seccionLinks'>
            <button className='botonLink'>
              <Link smooth={true} to="elementoContacto" duration={92}>
                CONTACTO
              </Link>
            </button>
          </section>

        </div>

        <hr className='lineaSeparacion'/>

        <section className='seccionRedesSociales'>
          <a href='https://www.facebook.com/DestructorasHSM' target="_blank" rel='noreferrer' className='redesSociales'>
            <FaFacebookF className='iconoRedSocial'/>
          </a>
          <a href='https://www.youtube.com/user/DestructorasHSM' target="_blank" rel='noreferrer' className='redesSociales'>
            <FaYoutube className='iconoRedSocial'/>
          </a>
          <a href='http://www.twitter.com/DestructorasHSM/' target="_blank" rel='noreferrer' className='redesSociales'>
            <FaXTwitter className='iconoRedSocial'/>
          </a>
        </section>

      </section>

      <section className='seccionInformacion'>

        <div className='areaInformacion'>

          <section className='textoInformacion'>
            <i className="espacioIcono">
              <FaLocationDot className='iconos'/>
            </i>
            <p className="escritoInformacion">
              Santa Bernardita 12025, Parque Industrial Puerta Sur, San Bernardo
            </p>
          </section>

          <section className='textoInformacion'>
            <i className="espacioIcono">
              <BsTelephoneFill className='iconos'/>
            </i>
            <p className="escritoInformacion">
              (02) 2396 4723
            </p>
          </section>

          <section className='textoInformacion'>
            <i className="espacioIcono">
              <FaClock className='iconos'/>
            </i>
            <p className="escritoInformacion">
              Horario Atención: Lunes a Jueves de 08:30 a 18:00 - Viernes de 08:30 a 16:00
            </p>
          </section>

          <section className='textoInformacion'>
            <i className="espacioIcono">
              <IoMdMail className='iconos'/>
            </i>
            <a href="mailto:hsm@imex.cl" className='mail'>
              hsm@imex.cl
            </a>
          </section>

          <section className='textoInformacion'>
            <i className="espacioIcono">
              <IoMdDownload className='iconos'/>
            </i>
            <p className="escritoInformacion">
              Descarga tu boleta AQUÍ
            </p>
          </section>
          
        </div>

        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106397.25341994726!2d-70.7413084435303!3d-33.55560629661754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c53e2bb1ca9f%3A0x40b650dc2884b8a0!2sDestructoras%20HSM%20Chile!5e0!3m2!1ses-419!2scl!4v1727959784588!5m2!1ses-419!2scl" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='cuadroMapa' title='mapaHSM'></iframe>
        </div>

      </section>

      <div className='mapaAbajo'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106397.25341994726!2d-70.7413084435303!3d-33.55560629661754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c53e2bb1ca9f%3A0x40b650dc2884b8a0!2sDestructoras%20HSM%20Chile!5e0!3m2!1ses-419!2scl!4v1727959784588!5m2!1ses-419!2scl" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='cuadroMapaAbajo' title='mapaHSM' />
        </div>

    </div>

  )
}

export default Footer;