import React from 'react';
import './nosotros.css';

import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3, TbCircleNumber4 } from "react-icons/tb";
import Carousel from 'react-bootstrap/Carousel';

import logoClasicoHSM from '../../imagenes/logoClasicoHSM.png';
import madeInGermany from '../../imagenes/madeInGermany.jpg';
import papelDestruido from '../../imagenes/papelDestruido.png';
import huellaVerde from '../../imagenes/huellaVerde.png';

function Nosotros(){

  return(

    <div className='nosotros'>
              
      <section className='sobreNosotros'>

        <h1 className='tituloNosotros'>
          Todo lo que necesitas saber sobre HSM
        </h1>

      </section>

      <section className='areaNosotros'>
        <section className='seccionNosotros'>
          <img src={logoClasicoHSM} alt="Logo Clásico HSM" className='imagenNosotros' />
          <TbCircleNumber1 className='iconoNumero'/>
          <h4 className='textoNosotros'>
            HSM fue fundada en una ciudad de Alemania llamada Salem en 1971.
          </h4>
        </section>
        <section className='seccionNosotros'>
          <img src={madeInGermany} alt="Made in Germany" className='imagenNosotros' />
          <TbCircleNumber2 className='iconoNumero'/>
          <h4 className='textoNosotros'>
            Se mantiene el distintivo de calidad hecho en alemania.
          </h4>
        </section>
        <section className='seccionNosotros'>
          <img src={papelDestruido} alt="Papel Destruido" className='imagenNosotros' />
          <TbCircleNumber3 className='iconoNumero'/>
          <h4 className='textoNosotros'>
            Contamos con destructoras para uso doméstico y de alto rendimiento.
          </h4>
        </section>
        <section className='seccionNosotros'>
          <img src={huellaVerde} alt="Huella Verde" className='imagenNosotros' />
          <TbCircleNumber4 className='iconoNumero'/>
          <h4 className='textoNosotros'>
            Sus fábricas poseen paneles fotovoltaicos reduciendo sus emisiones de CO2.
          </h4>
        </section>
      </section>

      <Carousel className='areaNosotrosSlide'>
        <Carousel.Item>
          <img src={logoClasicoHSM} alt="Logo Clásico HSM" className='slideNosotros' />
          <br />
          <TbCircleNumber1 className='iconoNumero'/>
          <br />
          <section className='seccionTextoNosotrosSlide'>
            <h4 className='textoNosotros'>
              HSM fue fundada en una ciudad de Alemania llamada Salem en 1971.
            </h4>
          </section>
          <br />
          <br />
        </Carousel.Item>
        <Carousel.Item>          
          <img src={madeInGermany} alt="Made in Germany" className='slideNosotros' />
          <br />
          <TbCircleNumber2 className='iconoNumero'/>
          <br />
          <section className='seccionTextoNosotrosSlide'>
            <h4 className='textoNosotros'>
              Se mantiene el distintivo de calidad hecho en alemania.
            </h4>
          </section>
          <br className='espacioNosotrosPequenno'/>
          <br />
          <br />
        </Carousel.Item>
        <Carousel.Item>                    
          <img src={papelDestruido} alt="Papel Destruido" className='slideNosotros' />
          <br />
          <TbCircleNumber3 className='iconoNumero'/>
          <br />
          <section className='seccionTextoNosotrosSlide'>
            <h4 className='textoNosotros'>
              Contamos con destructoras para uso doméstico y de alto rendimiento.
            </h4>
          </section>
          <br />
          <br />
        </Carousel.Item>
        <Carousel.Item>                    
          <img src={huellaVerde} alt="Huella Verde" className='slideNosotros' />
          <br />
          <TbCircleNumber4 className='iconoNumero'/>
          <br />
          <section className='seccionTextoNosotrosSlide'>
            <h4 className='textoNosotros'>
              Sus fábricas poseen paneles fotovoltaicos reduciendo sus emisiones de CO2.
            </h4>
          </section>
          <br />
          <br />
        </Carousel.Item>
      </Carousel>

    </div>

  )
}

export default Nosotros;