import {React, useState, useEffect} from 'react';

import './catalogo.css';

function Catalogo() {

  const [datosCatalogo, setCatalogo] = useState([{}]);
  const [datosCategoria, setCategoria] = useState([{}]);

  useEffect(() => {
    fetch("/catalogo").then(
      respuestaCatalogo => respuestaCatalogo.json()
    ).then(
      datosCatalogo => {
        setCatalogo(datosCatalogo);
        fetch("/categoria").then(
          respuestaCategoria => respuestaCategoria.json()
        ).then(
          datosCategoria => {
            setCategoria(datosCategoria);
          }
        )
      }
    )
  }, []);

  const [verBoton, setVerboton] = useState(true);

  const verOcultar = () => {
    setVerboton(!verBoton);
  };

  return (

    <div className='areaCatalogo'>

      <section className='seccionFiltroCategoria'>
        <button onClick={verOcultar} className='botonFiltro'>
          Filtrar por:
        </button>

        {!verBoton && datosCategoria.map((categoria, posicion) => (
          <a key={posicion} href={"categoria/"+categoria.id} className='linkCategoria'>
            {categoria.nombreCategoria}
          </a>
        ))}
        
        {!verBoton &&
          <hr className='lineaSeparatoria' />
        }
      </section>

      <section className='seccionCatalogo'>
        <section>
          <h1>
            Catálogo de Productos
          </h1>
          <hr  className='lineaSeparatoria'/>
        </section>

        <section className='seccionFiltroCategoriaMedio'>
          <button onClick={verOcultar} className='botonFiltro'>
            Filtrar por:
          </button>

          {!verBoton && datosCategoria.map((categoria, posicion) => (
            <a key={posicion} href={"categoria/"+categoria.id} className='linkCategoria'>
              {categoria.nombreCategoria}
            </a>
          ))}
          
          {!verBoton &&
            <hr className='lineaSeparatoria' />
          }
        </section>

        <section className='seccionProductos'>
          {datosCatalogo.map((catalogo, posicion) => (
            <section key={posicion} className='productoCatalogo'>
              <img src={catalogo.rutaImagen} alt={catalogo.descripcionImagen} className='imagenCatalogo' />
              <h1 className='nombreProductoCatalogo'>
                {catalogo.nombreProducto}
              </h1>
              <a href={"/catalogo/" + catalogo.id}>
                <button className='botonCatalogo'>
                  Ver Detalle
                </button>
              </a>
            </section>
          ))}
        </section>
      </section>
     
    </div>

  )
}

export default Catalogo;