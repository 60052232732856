import React from 'react';
import './slide.css';

import Carousel from 'react-bootstrap/Carousel';

import lineaPrensasVerticales from '../../imagenes/lineaPrensasVerticales.png';
import destructoraDocumentos from '../../imagenes/destructoraDocumentos.jpg';
import destructoraDiscosDuros from '../../imagenes/destructoraDiscosDuros.jpg';
import compactadorasTambor from '../../imagenes/compactadorasTambor.jpg';
import compactadorasCanal from '../../imagenes/compactadorasCanal.jpg';

function Slide(){

  return(

    <div className='slide'>

      <section className='seccionSlide'>

        <Carousel>
          <Carousel.Item>
            <a href="categoria/subcategoria/8">
              <img src={lineaPrensasVerticales} alt="Línea de Prensas Verticales" className='imagenSlide' />
            </a>
            <br />
            <br />
            <br />
          </Carousel.Item>
          <Carousel.Item>
            <a href="categoria/subcategoria/4">
              <img src={destructoraDocumentos} alt="Destructoras de Documentos" className='imagenSlide' />
            </a>
            <br />
            <br />
            <br />
          </Carousel.Item>
          <Carousel.Item>
            <a href="categoria/subcategoria/3">
              <img src={destructoraDiscosDuros} alt="Destructoras de Discos Duros" className='imagenSlide' />
            </a>
            <br />
            <br />
            <br />
          </Carousel.Item>
          <Carousel.Item>
            <a href="categoria/subcategoria/10">
              <img src={compactadorasTambor} alt="Compactadoras de Tambor" className='imagenSlide' />
            </a>
            <br />
            <br />
            <br />
          </Carousel.Item>
          <Carousel.Item>
            <a href="categoria/2">
              <img src={compactadorasCanal} alt="Compactadoras de Canal" className='imagenSlide' />
            </a>
            <br />
            <br />
            <br />
          </Carousel.Item>
        </Carousel>
        
      </section>

    </div>
  )

}

export default Slide;