import React from 'react';

import Slider from '../../componentes/slide/slide.jsx';
import Categorias from '../../componentes/categorias/categorias.jsx';
import Nosotros from '../../componentes/nosotros/nosotros.jsx';
import Muestra from '../../componentes/muestra/muestra.jsx';

import { Element } from 'react-scroll';

function Home() {
  return(

    <div>

      <section>
        <Slider />
      </section>

      <section>
        <Element name="elementoCategorias">
          <Categorias id="paginaCategorias"/>
        </Element>
      </section>

      <section>
        <Nosotros />
      </section>

      <section>
        <Muestra />
      </section>

    </div>

  )
}

export default Home;