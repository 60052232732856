import {React, useState, useEffect} from 'react';
import './header.css';

import { FaSearch, FaHome } from "react-icons/fa"
import { LuMenuSquare } from "react-icons/lu";
import { IoBusiness } from "react-icons/io5";
import { GrCatalog } from "react-icons/gr";
import { IoMdContact } from "react-icons/io"

import { Link } from 'react-scroll';

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";

import logo from '../../imagenes/logo.jpg';

function Header() {

  const [datosCategoria, setDatosCategoria] = useState([{}]);

  useEffect(() => {
    fetch(`/categoria`).then(
      respuestaCategoria => respuestaCategoria.json()
    ).then(
      datosCategoria => {
        setDatosCategoria(datosCategoria)
      }
    )
  }, []);

  const [datosProductos, setDatosProductos] = useState([{}]);

  useEffect(() => {
    fetch(`/catalogo`).then(
      respuestaProductos => respuestaProductos.json()
    ).then(
      datosProductos => {
        setDatosProductos(datosProductos)
      }
    )
  }, []);

  const [barraBusqueda, setBarraBusqueda] = useState("");
  const [primerasLetras, setPrimerasLetras] = useState([]);

  const manejarCambio = (evento) => {
    setBarraBusqueda(evento.target.value);
    if (evento.target.value.length > 0) {
      const productoCoincidente = datosProductos.filter((producto) => producto.nombreProducto.toString().toLowerCase().includes(evento.target.value.toLowerCase()) || producto.codigoProducto.toString().toLowerCase().includes(evento.target.value.toLowerCase())) ;
      setPrimerasLetras(productoCoincidente.slice(0, 5));
    } else {
      setPrimerasLetras([]);
    }
  }

  return(
    <div className='header'>

      <section className='seccionLogo'>
        <a href="/">
          <img src={logo} alt='Logo de HSM' className='logoHeader' />
        </a>
      </section>

      <div className='paginas'>

        <a href='/' className='linkNavegacion'>
          HOME
        </a>

        <Dropdown>

          <Dropdown.Toggle variant='' id="categoriasDesplegable" className='botonDropdown'>
            CATEGORÍAS
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {datosCategoria.map((categoria, posicion) => (
              <a key={posicion} href={"/categoria/"+categoria.id} className="menuDropdown">
                <p>
                  {categoria.nombreCategoria}
                </p>
              </a>
            ))}  
          </Dropdown.Menu>

        </Dropdown>

        <a href='/nosotros' className='linkNavegacion'>
          NOSOTROS
        </a>

        <a href='/catalogo' className='linkNavegacion'>
          CATÁLOGO
        </a>

        <button className='botonLinkNavegacion'>
          <Link smooth={true} to="elementoContacto" duration={92}>
            CONTACTO
          </Link>
        </button>

      </div>

      <section className='seccionMenu'>
        <Dropdown>

            <Dropdown.Toggle variant='' id="menuDesplegable">
              <LuMenuSquare className='menu'/>
            </Dropdown.Toggle>

            <Dropdown.Menu>

              <a href="/" value="home">
                <section className='seccionNavegacion'>
                  <FaHome className="iconoLink"/>
                </section>
              </a>

              <a href="/nosotros" value="nosotros">
                <section className='seccionNavegacion'>
                  <IoBusiness className="iconoLink"/>
                </section>
              </a>

              <a href="/catalogo" value="catalogo">
                <section className='seccionNavegacion'>
                  <GrCatalog className="iconoLink"/>
                </section>
              </a>

              <button className='botonMenuContacto' value="contacto">
                <Link smooth={true} to="elementoContacto" duration={92}>
                  <section className='seccionNavegacion'>
                    <IoMdContact className="iconoLink"/>
                  </section>
                </Link>
              </button>

            </Dropdown.Menu>
          </Dropdown>
          
      </section>

      <Dropdown className='cuadroBusqueda'>

        <Dropdown.Toggle variant='' id="busquedaDesplegable" className='botonDropdown'>
          <FaSearch  className='busqueda'/>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdownMenuDesplegable'>

          <section className='menuBusquedaDesplegable'>
            
            <input type='text' name='barraBusqueda' placeholder='Búsqueda de Productos' value={barraBusqueda} onChange={manejarCambio} className='barraBusqueda'/>

            {primerasLetras && primerasLetras.map((producto, nombreProducto) => (
              <Dropdown.Item key={nombreProducto} href={"/catalogo/"+producto.id} onClick={() => {setBarraBusqueda(producto.nombreProducto);}} className='itemsProductosEncontrados'>

                <p className='linkProductosEncontrados'>
                    {producto.nombreProducto}
                    <br />
                    {producto.codigoProducto}
                </p>

                <img src={producto.rutaImagen} alt={producto.descripcionImagen} className='imagenProductoEncontrado' />

              </Dropdown.Item>
            ))}

          </section>

        </Dropdown.Menu>

      </Dropdown>

    </div>
    )

}

export default Header;