import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import { TiArrowBack } from "react-icons/ti";

import '../filtros.css';

function CategoriaFiltrada() {

  const {idCategoria} = useParams();
  const [datosCategoriaFiltrada, setCategoriaFiltrada] = useState([{}]);
  const [datosSubcategoriaFiltrada, setSubcategoriaFiltrada] = useState([{}]);
  const [datosCatalogoFiltrado, setCatalogoFiltrado] = useState([{}]);
  
  useEffect(() => {
    fetch(`/filtrocategoria/${idCategoria}`).then(
      respuestaCategoria => respuestaCategoria.json()
    ).then(
      datosCategoria => {
        setCategoriaFiltrada(datosCategoria);
        fetch(`/subcategoria/${idCategoria}`).then(
          respuestaSubcategoria => respuestaSubcategoria.json()
        ).then(
          datosSubcategoria => {
            setSubcategoriaFiltrada(datosSubcategoria);
            fetch(`/categoria/${idCategoria}`).then(
              respuestaProducto => respuestaProducto.json()
            ).then(
              datosProducto => {
                setCatalogoFiltrado(datosProducto)
              }
            )
          }
        )
      }
    )
  }, [idCategoria]);

  const [verBoton, setVerboton] = useState(true);

  const verOcultar = () => {
    setVerboton(!verBoton);
  };

  return (

    <div className='areaCatalogoFiltrado'>

      <section className='seccionFiltros'>

        <h4>
          {datosCategoriaFiltrada.nombreCategoria}
        </h4>

        <button onClick={verOcultar} className='botonVerOcultar'>
          Filtrar por:
        </button>

        {!verBoton && datosSubcategoriaFiltrada.map((subcategoria, posicion) => (
          <a key={posicion} href={"subcategoria/"+subcategoria.id} className='linkSubcategoria'>
            {subcategoria.nombreSubcategoria}
          </a>
        ))}
        
        {!verBoton &&
          <hr className='lineaSeparatoria' />
        }

      </section>

      <section className='seccionCatalogoFiltrado'>
        <div className='areaTituloFiltro'>
          <a href={"/catalogo"}>
            <TiArrowBack  className='iconoVolverAtras'/>
          </a>
          <h1>
            {datosCategoriaFiltrada.nombreCategoria}
            <hr className='lineaSeparatoria' />
          </h1>
        </div>

        <section className='seccionFiltrosEnMedio'>

          <button onClick={verOcultar} className='botonVerOcultar'>
            Filtrar por:
          </button>

          {!verBoton && datosSubcategoriaFiltrada.map((subcategoria, posicion) => (
            <a key={posicion} href={"subcategoria/"+subcategoria.id} className='linkSubcategoria'>
              {subcategoria.nombreSubcategoria}
            </a>
          ))}

          {!verBoton &&
            <hr className='lineaSeparatoria' />
          }

        </section>
        
        <section className='areaCatalogoFiltrado'>
          {datosCatalogoFiltrado.map((catalogoFiltrado, posicion) => (
            <section key={posicion} className='productoCatalogoFiltrado'>
              <img src={catalogoFiltrado.rutaImagen} alt={catalogoFiltrado.descripcionImagen} className='imagenCatalogoFiltrado' />
              <h1 className='nombreProductoCatalogoFiltrado'>
                {catalogoFiltrado.nombreProducto}
              </h1>
              <a href={"/catalogo/" + catalogoFiltrado.id}>
                <button className='botonCatalogoFiltrado'>
                  Ver Detalle
                </button>
              </a>
            </section>
          ))}
          </section>
      </section>      
      
    </div>
  )

}

export default CategoriaFiltrada;