import React, {useState, useEffect} from 'react';

import './categorias.css';

function Categorias() {

  const [datosCategoria, setCategoria] = useState([{}]);

  useEffect(() => {
    fetch("/categoria").then(
      respuesta => respuesta.json()
    ).then(
      datos => {
        setCategoria(datos)
      }
    )
  }, []);

  return (

    <div className='categorias'>

      <section className='seccionDescripcionCategoria'>

        <h3 className='esloganHSM'>
          Cuidar la información cuesta menos que perder datos
        </h3>

      </section>

      <section className='areaCategoria'>
    
        {datosCategoria.map((categoria, posicion) => (
          <section key={posicion} className='seccionCategoria'>
            <a href={"/categoria/"+categoria.id} title={categoria.descripcionLink} className='linkCategoria'>
              <img src={categoria.rutaImagen} alt={categoria.descripcionImagen} className='imagenCategoria' />
              <h3 className='nombreCategoria'>
                {categoria.nombreCategoria}
              </h3>
            </a>
          </section>
        ))}
      
      </section>

    </div>

  )
}

export default Categorias;