import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Header from './componentes/header/header.jsx';
import Contacto from './componentes/contacto/contacto.jsx';
import Footer from './componentes/footer/footer.jsx';

import { Element } from 'react-scroll';

import Home from './paginas/home/home.jsx';
import PaginaNosotros from './paginas/nosotros/paginaNosotros.jsx';
import Catalogo from './paginas/catalogo/catalogo.jsx';
import CategoriaFiltrada from './paginas/filtros/1_categoriaFiltrada/categoriaFiltrada.jsx';
import SubcategoriaFiltrada from './paginas/filtros/2_subcategoriaFiltrada/subcategoriaFiltrada.jsx';
import DetalleSubcategoriaFiltrada from './paginas/filtros/3_detalleSubcategoriaFiltrada/detalleSubcategoriaFiltrada.jsx';
import DetalleProducto from './paginas/producto/producto.jsx';

function App() {

  return (

    <div>

      <header>

        <Element name="elementoHeader">
          <Header />
        </Element>

      </header>

      <div className='fondoBody'>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/nosotros' element={<PaginaNosotros />} />
            <Route path='/catalogo' element={<Catalogo />} />
            <Route path='/categoria/:idCategoria' element={<CategoriaFiltrada />} />
            <Route path='/categoria/subcategoria/:id' element={<SubcategoriaFiltrada />} />
            <Route path='/categoria/subcategoria/detalle/:id' element={<DetalleSubcategoriaFiltrada />} />
            <Route path='/catalogo/:id' element={<DetalleProducto />} />
            <Route path='/catalogo/:nombreProducto' element={<DetalleProducto />} />
          </Routes>
        </Router>
      </div>

      <section>

        <Element name="elementoContacto">
          <Contacto />
        </Element>
      
      </section>

      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default App;
