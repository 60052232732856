import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import '../filtros.css';

import { TiArrowBack } from "react-icons/ti";

function DetalleSubcategoriaFiltrada() {

  const {id} = useParams();
  const [datosDetalleSubcategoriaFiltrada, setDetalleSubcategoriaFiltrada] = useState([{}]);
  const [datosSubcategoriaFiltrada, setSubcategoriaFiltrada] = useState([{}]);
  const [categoriaFiltrada, setCategoriaFiltrada] = useState([{}]);
  const [datosCatalogoFiltrado, setCatalogoFiltrado] = useState([{}]);

  useEffect(() => {
    fetch(`/filtrodetallesubcategoria/${id}`).then(
      respuestaDetalleSubcategoria => respuestaDetalleSubcategoria.json()
    ).then(
      datosDetalleSubcategoria => {
        setDetalleSubcategoriaFiltrada(datosDetalleSubcategoria);
        fetch(`/filtrosubcategoria/${datosDetalleSubcategoria.idSubcategoria}`).then(
          respuestaSubcategoria => respuestaSubcategoria.json()
        ).then(
          datosSubcategoria => {
            setSubcategoriaFiltrada(datosSubcategoria);
            fetch(`/filtrocategoria/${datosSubcategoria.idCategoria}`).then(
              respuestaCategoria => respuestaCategoria.json()
            ).then(
              datosCategoria => {
                setCategoriaFiltrada(datosCategoria);
              }
            )
          }
        )
      }
    )
  }, [id]);

  useEffect(() => {
  fetch(`/categoria/subcategoria/detalle/${id}`).then(
    respuestaProducto => respuestaProducto.json()
  ).then(
    datosProducto => {
      setCatalogoFiltrado(datosProducto)
    }
  )
  }, [id]);

  return (

    <div className='filtro'>

      <section className='areaTituloFiltro'>
        <a href={"/categoria/subcategoria/"+datosDetalleSubcategoriaFiltrada.idSubcategoria}>
          <TiArrowBack  className='iconoVolverAtras'/>
        </a>
        <h1>
          {categoriaFiltrada.nombreCategoria}
        </h1>
        <h2>
          Filtrado por: {datosSubcategoriaFiltrada.nombreSubcategoria}
        </h2>
        <h3>
          Detalle: {datosDetalleSubcategoriaFiltrada.descripcionSubcategoria}
          <hr className='lineaSeparatoria' />
        </h3>
      </section>

      <section className='seccionCatalogoFiltrado'>
        <div className='areaCatalogoFiltrado'>
          {datosCatalogoFiltrado.map((catalogoFiltrado, posicion) => (
            <section key={posicion} className='productoCatalogoFiltrado'>
              <img src={catalogoFiltrado.rutaImagen} alt={catalogoFiltrado.descripcionImagen} className='imagenCatalogoFiltrado' />
              <h1 className='nombreProductoCatalogoFiltrado'>
                {catalogoFiltrado.nombreProducto}
              </h1>
              <a href={"/catalogo/" + catalogoFiltrado.id}>
                <button className='botonCatalogoFiltrado'>
                  Ver Detalle
                </button>
              </a>
          </section>
          ))}
        </div>
      </section>
      
    </div>
  )

}

export default DetalleSubcategoriaFiltrada;