import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import '../filtros.css';

import { TiArrowBack } from "react-icons/ti";

function SubcategoriaFiltrada() {

  const {id} = useParams();
  const [datosSubcategoriaFiltrada, setSubcategoriaFiltrada] = useState([{}]);
  const [datosDescripcionSubcategoriaFiltrada, setDescripcionSubcategoriaFiltrada] = useState([{}]);
  const [categoriaFiltrada, setCategoriaFiltrada] = useState([{}]);
  const [datosCatalogoFiltrado, setCatalogoFiltrado] = useState([{}]);

  useEffect(() => {
    fetch(`/filtrosubcategoria/${id}`).then(
      respuestaSubcategoria => respuestaSubcategoria.json()
    ).then(
      datosSubcategoria => {
        setSubcategoriaFiltrada(datosSubcategoria);
        fetch(`/filtrocategoria/${datosSubcategoria.idCategoria}`).then(
          respuestaCategoria => respuestaCategoria.json()
        ).then(
          datosCategoria => {
            setCategoriaFiltrada(datosCategoria);
            fetch(`/detallesubcategoria/${id}`).then(
              respuestaDescripcionSubcategoria => respuestaDescripcionSubcategoria.json()
            ).then(
              datosDescripcionSubcategoria => {
                setDescripcionSubcategoriaFiltrada(datosDescripcionSubcategoria);
                fetch(`/categoria/subcategoria/${id}`).then(
                  respuestaProducto => respuestaProducto.json()
                ).then(
                  datosProducto => {
                    setCatalogoFiltrado(datosProducto)
                  }
                )
              }
            )
          }
        )
      }
    )
  }, [id]);

  const [verBoton, setVerboton] = useState(true);

  const verOcultar = () => {
    setVerboton(!verBoton);
  };

  return (

    <div className='filtro'>

      <section className='seccionFiltros'>

        <h4>
          {datosSubcategoriaFiltrada.nombreSubcategoria}
        </h4>

        <button onClick={verOcultar} className='botonVerOcultar'>
          Filtrar por:
        </button>

        {!verBoton && datosDescripcionSubcategoriaFiltrada.map((descripcionSubcategoria, posicion) => (
          <a key={posicion} href={"detalle/"+descripcionSubcategoria.id} className='linkSubcategoria'>
            {descripcionSubcategoria.descripcionSubcategoria}
          </a>
        ))}
        
        {!verBoton &&
          <hr className='lineaSeparatoria' />
        }

      </section>

      <section className='seccionCatalogoFiltrado'>
        <div className='areaTituloFiltro'>
          <a href={"/categoria/"+datosSubcategoriaFiltrada.idCategoria}>
            <TiArrowBack  className='iconoVolverAtras'/>
          </a>
          <h1>
            {categoriaFiltrada.nombreCategoria}
          </h1>
          <h2>
            Filtrado por: {datosSubcategoriaFiltrada.nombreSubcategoria}
            <hr className='lineaSeparatoria'/>
          </h2>
        </div>

        <section className='seccionFiltrosEnMedio'>

          <button onClick={verOcultar} className='botonVerOcultar'>
            Filtrar por:
          </button>

          {!verBoton && datosDescripcionSubcategoriaFiltrada.map((descripcionSubcategoria, posicion) => (
            <a key={posicion} href={"detalle/"+descripcionSubcategoria.id} className='linkSubcategoria'>
              {descripcionSubcategoria.descripcionSubcategoria}
            </a>
          ))}

          {!verBoton &&
            <hr className='lineaSeparatoria' />
          }

        </section>

        <div className='areaCatalogoFiltrado'>
          {datosCatalogoFiltrado.map((catalogoFiltrado, posicion) => (
            <section key={posicion} className='productoCatalogoFiltrado'>
              <img src={catalogoFiltrado.rutaImagen} alt={catalogoFiltrado.descripcionImagen} className='imagenCatalogoFiltrado' />
              <h1 className='nombreProductoCatalogoFiltrado'>
                {catalogoFiltrado.nombreProducto}
              </h1>
              <a href={"/catalogo/" + catalogoFiltrado.id}>
                <button className='botonCatalogoFiltrado'>
                  Ver Detalle
                </button>
              </a>
          </section>
          ))}
        </div>
      </section>
      
    </div>
  )

}

export default SubcategoriaFiltrada;