import React from 'react';
import './paginaNosotros.css';

import logoHSM from '../../imagenes/logoHSM.jpg';
import confidencialidad from '../../imagenes/confidencialidad.png';

function PaginaNosotros() {

  return(

    <div className='paginaNosotros'>

      <section className='areaQuienMision'>
        <section className='seccionQuienMision'>
          <img src={logoHSM} alt="Logo HSM" className='imagenQuienMision' />
          <h1 className='tituloQuienMision'>
            ¿QUIÉNES SOMOS?
          </h1>
          <h4 className='escritoQuienMision'>
            HSM es una empresa la cual desarrolla, produce y vende productos e instalaciones capaces de prensar materiales reciclables, triturar papel y soportes de datos electrónicos. Nos hemos convertido en punteros de productos y servicios en los sectores de tecnología y oficina. Mantenemos nuestro compromiso con la producción en Alemania junto a su sello de calidad «Made in Germany» lo que nos lleva a relacionarnos con la precisión, durabilidad y relacion calidad-precio.
          </h4>
        </section>
      </section>

      <section className='areaQuienMision'>
        <section className='seccionQuienMision'>
          <img src={confidencialidad} alt="Confidencialidad" className='imagenQuienMision' />
          <h1 className='tituloQuienMision'>
            MISIÓN
          </h1>
          <h4 className='escritoQuienMision'>
            En HSM nos preocupamos de entregar asesoramiento óptimo en el cuidado de la eliminación de información. Buscamos que todas nuestras máquinas funcionen de manera optima 24/7. Ante cualquier desperfecto o avería el soporte entegrado de una pronta resolución y esta sea satisfactoria para el cliente.
          </h4>
        </section>
      </section>

    </div>
  )
}

export default PaginaNosotros;